import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Use Link for navigation
import EmpowerLogo from '../images/logo4.png';
import './navbar.css'; // Import the custom CSS for hover effect
import logosmall from '../images/logo5.png'
function Nav2() {
    const location = useLocation();
    const handleLogoClick = () => {
        window.location.href = '/'; // Change to the desired URL
      };

    // Helper function to check if the link is active
    const isActive = (path) => location.pathname === path ? 'single active' : 'single';
    return (
        <div>
            <nav className="navbar navbar-expand-lg p-lg-0  nav-color">
                <div className='container-fluid nav-color '>
                    {/* Show logo */}
                    <img src={EmpowerLogo} className=" nav-img img-fluid d-none d-lg-block" alt='Empower Logo' onClick={handleLogoClick} />
                    <img src={logosmall} className="nav-img-sm img-fluid d-lg-none d-block" alt='Empower Logo Small' onClick={handleLogoClick} />

                    {/* Navbar toggler for small screens */}
                    <button className="navbar-toggler border-0 d-lg-none" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#top-navbar" aria-controls="top-navbar">
                        <i className='lni lni-grid-alt'></i>
                    </button>

                    {/* Offcanvas menu for small screens */}
                    <div className="offcanvas offcanvas-start d-lg-none" tabIndex="-1" id="top-navbar" aria-labelledby="top-navbarLabel">
                        <button className="navbar-toggler border-0 mt-3" type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                            <div className="d-flex justify-content-end w-100">
                                <i className="lni lni-cross-circle"></i>
                            </div>
                        </button>






                        <ul className="navbar-nav p-2  ">

                            <li className="nav-item ">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li><hr className="dropdown-divider dd" /></li>
                            <li className="nav-item  ">
                                <a className="nav-link " href="/aboutus">About Us</a>
                            </li>
                            <li><hr className="dropdown-divider dd" /></li>
                            <li className="nav-item dropdown  ">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown">
                                    Products
                                </a>
                                <ul className="dropdown-menu dropdown-menu-offcanvas" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item  " to="/product/PerkinsGenerator">Perkins Generators</Link></li>
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item " to="/product/CumminsGenerators">Cummins Generators</Link></li>
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item" to="/product/IndustrialGradeGenerator">Industrial Grade Generator</Link></li>
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item" to="/product/ProtableGenerator">Portable Generators</Link></li>
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item" to="/product/GeneratorsAndAlternators">CKD Generator and Alternators</Link></li>
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item" to="/product/Solarsolutions">Solar Solutions</Link></li>
                                </ul>
                            </li>


                            <li><hr className="dropdown-divider dd" /></li>
                            <li className="nav-item  dropdown ">
                                <a className="nav-link dropdown-toggle" href="/internationalcollaboration" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <ul className="dropdown-menu dropdown-menu-offcanvas" aria-labelledby="navbarDropdown1">
                                    <li><Link className="dropdown-item " to="/internationalcollaboration">International Collaborations</Link></li>
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item" to="/aftersales">After-Sales Support</Link></li>
                                    
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    <li><Link className="dropdown-item " to="/events">Our Events</Link></li>
                                    
                                </ul>
                            </li>
                            <li><hr className="dropdown-divider dd" /></li>
                            <li className="nav-item  ">
                                <a className="nav-link active" href="/contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>



                    {/* Regular navbar items for large screens */}
                    <div className="collapse navbar-collapse d-none d-lg-flex ">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item p-1 mx-2 ">
                                <Link className='nav-link single' to="/">Home</Link>
                            </li>
                            <li className="nav-item p-1 mx-2 ">
                                <Link className={`${isActive('/aboutus')} nav-link`} to="/aboutus">About Us</Link>
                            </li>
                            <li className="nav-item dropdown p-1 mx-2 ">
                                <a className="nav-link dropdown-toggle " href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Products
                                </a>
                                <ul className="dropdown-menu mt-2" aria-labelledby="navbarDropdown">
                                    <li><Link className="dropdown-item custom-line" to="/product/PerkinsGenerator">Perkins Generators</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/product/CumminsGenerators">Cummins Generators</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/product/IndustrialGradeGenerator">Industrial Grade Generator</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/product/ProtableGenerator">Portable Generators</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/product/GeneratorsAndAlternators">CKD Generator and Alternators</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/product/Solarsolutions">Solar Solutions</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown p-1 mx-2 ">
                                <a className="nav-link dropdown-toggle " href="/" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <ul className="dropdown-menu mt-2" aria-labelledby="navbarDropdown1">
                                    <li><Link className="dropdown-item custom-line" to="/internationalcollaboration">International Collaborations</Link></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/aftersales">After-Sales Support</Link></li>
                                    
                                    <li><hr className="dropdown-divider m-0" /></li>
                                    
                                    <li><Link className="dropdown-item " to="/events">Our Events</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item p-1 mx-2 fw">
                                <Link className={`${isActive('/contact')} nav-link`} to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
        </div>
    );
}

export default Nav2;
