import React from 'react'
import Background from '../components/Background'
import nasa from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg'
import Eventsdetail from '../components/Eventsdetail'
import EventVideos from '../components/EventVideos'
function Events() {
  return (
    <div>
        <Background title="Events" backgroundImage={nasa}/>
        <Eventsdetail/>
        <EventVideos/>
    </div>
  )
}

export default Events