import React, { useState } from 'react';
import './navbar.css';
import TextReveal from './Textreveal';

import PC1 from '../images/PC1.png'
import PC2 from '../images/danist-soh-8Gg2Ne_uTcM-unsplash.jpg'
import PC3 from '../images/luke-besley-k5l-zbRSPds-unsplash.jpg'
import PC4 from '../images/giorgio-trovato-mIlvCv21W1s-unsplash.jpg'
import PC5 from '../images/ej-yao-D46mXLsQRJw-unsplash.jpg'

function Imageslider() {
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
        {
            image: PC1 ,
            text1: "Providing Solutions -⚡",
            text2: "Empowering Business   "
        },
        {
            image: PC2,
            text1: "Providing Solutions -⚡",
            text2: "Empowering Business   "
        },
        {
            image: PC3,
            text1: "Providing Solutions -⚡",
            text2: "Empowering Business  "
        },
        {
            image: PC4,
            text1: "Providing Solutions -⚡",
            text2: "Empowering Business  "
        },
        {
            image: PC5,
            text1: "Providing Solutions -⚡",
            text2: "Empowering Business  "
        },
    ];

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };

    return (
        <div>
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {slides.map((slide, index) => (
                        <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
                            <img src={slide.image} className="d-flex justify-content-center w-100 carousel-image" alt={`Slide ${index + 1}`} />
                            <div className="carousel-caption  d-flex  justify-content-center   Tagline">
                                <TextReveal text1={slide.text1} text2={slide.text2} key={activeIndex} /> {/* Reset animation on change */}
                                <div className='caption'>
                                <p>“Committed to shaping a sustainable future, we focus on harnessing clean and efficient energy technologies to meet the evolving needs of our community.”</p>
                            </div>
                            </div>
                            
                            
                        </div>
                    ))}
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev" onClick={() => handleSlideChange((activeIndex - 1 + slides.length) % slides.length)}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next" onClick={() => handleSlideChange((activeIndex + 1) % slides.length)}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    );
}

export default Imageslider;
