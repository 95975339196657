import React from 'react'
import './others.css'
import { Link } from 'react-router-dom';
import Img1 from '../images/muhammed-shazin-jisia2SxQog-unsplash.jpg'
import Img2 from '../images/dima-solomin-8gXzLPWPu7E-unsplash.jpg'
function AboutUs() {
    return (
        <div className='container-fluid '>
            <div className='row  p-3 about-heading-text mt-lg-4 mt-md-3 mt-2'>
                <p>"We stand at the forefront of delivering advanced energy solutions for a sustainable future."</p>
            </div>

            <div className='row mx-lg-5 my-lg-5 mx-md-2 mx-0  '>
                <div className='col-12 order-lg-1 order-2 col-lg-6 col-md-12  p-lg-4 p-1 justify-content-center align-items-center'>
                    <div className='about-text-img-side p-2  p-lg-4 p-md-3 ml-lg-4 ml-0 '>
                        <h4>About Us</h4>
                        <h1>An Established and reputable company</h1>
                        <p>
                            Established in 2010, Empower PK has been a cornerstone of the Pakistan's manufacturing sector, specializing in cutting-edge industrial equipment and comprehensive service offerings. Committed to advancing sustainability, we champion innovative solutions that optimize energy efficiency and environmental stewardship.
                        </p>
                        <div className='mt-auto'>
                        <Link to="/contact" >
                        <button className='button' >Contact Us</button>
                            
                        </Link>
                        </div>
                        
                        
                    </div>

                </div>
                <div className='col-12 order-lg-2 order-1 col-lg-6 col-md-12 p-0  p-lg-3'>
                    <div className='image-container bg-pink'>
                        <img src={Img1} alt='' className="image image1  image-fluid" />
                        <img src={Img2}  alt='' className="image image2  image-fluid" />
                    </div>

                </div>

            </div>


        </div>
    )
}

export default AboutUs