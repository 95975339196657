import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

function TextReveal({ text1, text2 }) {

    return (
        <div className="text-reveal">
            <h4>
                <Typewriter
                    words={[text1]}
                    loop={1} // Only loop once
                    cursor={false}
                    typeSpeed={55}
                    deleteSpeed={50}
                    delaySpeed={1000}
                    startDelay={5000}
                />
                
            </h4>
            <h1>
                <Typewriter
                    words={[text2]}
                    loop={1} // Only loop once
                    cursor={false}
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                    startDelay={9000} // Delay before starting the second text
                />
            </h1>
        </div>
    );
}

export default TextReveal;
