import React from 'react'
import Background from '../components/Background'
import nasa from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg'
import ContactForm from '../components/ContactForm'
function Contact() {
  return (
    <div>
      <Background title="Contact Us" backgroundImage={nasa}/>
        <ContactForm />
    </div>
  )
}

export default Contact