import React from 'react';
import './others.css';

function Background({ title, backgroundImage }) {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div
          className='bgimg-container p-3'
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {title && <h1 className=''>{title}</h1>}
        </div>
      </div>
    </div>
  );
}

export default Background;
