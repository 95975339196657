import React from 'react';
import './homepg.css';  // Make sure to import the CSS file

function LogoComp({ mainHeading, subHeading, logos }) {

  if (!logos || logos.length === 0) {
    return <div>No logos available</div>;
  }
  return (
    <div className="container-fluid text-center mt-2 p-4">
      {subHeading &&  <h3 className="lead custom-h3">{subHeading}</h3>}
      <h1 className="display-6 custom-h1">{mainHeading}</h1>
      
      <div className="row d-flex   p-1 justify-content-around ">
        {logos.map((logo, index) => (
          <div key={index} className="col-6 col-md-4 col-lg-2 col-sm-3 mt-2 ">
            <img
              src={logo.src}
              alt={logo.alt || `Logo ${index + 1}`}
              className="img-fluid logo-img"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LogoComp;
