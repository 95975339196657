import React from 'react'
import ActionImage from '../images/Wavy_Bus-27_Single-06.jpg'
import './others.css'; // Import custom CSS file

function ActionService() {
  return (
    <div className='container-fluid mt-lg-0'>
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-0 col-0  '>
          <div className='action-service-image-container p-2 d-lg-block d-none'>
            <img src={ActionImage} alt='Action Service' className='img-fluid' />
          </div>
        </div>
        <div className='col-lg-6 col-md-12 col-12 d-flex align-items-center'>
          <div className='text-container'>
            <h4>Empower PK Action Service 24/7</h4>
            <p>Contact us on a designated phone number after office hours or on holidays. Our action service team is always ready to assist you.</p>
            <ul>
              <li>This is a 24/7 on-call service and customers contact on a designated phone number after office hours or on holidays.</li>
              <li>Customer calling on action service team responded by human not by answering machine.</li>
              <li>Action service team is committed to respond to customer immediately and breakdown recovery within reasonable time depending upon travelling distance & scope of repair involved.</li>
              <li>Service Technician with mobile service van on each location responsible to attend action service responsibility.</li>
              <li>Empower PK warehouses, workshops and special tools becomes accessible within a reasonable time to respond breakdown recovery after normal office hours or during off days</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionService
