import React from 'react'
import activites from '../images/Book openactivites.png'
import Aperture from '../images/Aperture.png'
import Codepen from '../images/Codepen.png'
import './others.css'

function AfterSalesHeader() {
    return (
        <div className='container-fluid '>
            <div className='row heading mt-lg-5 mt-md-2 mt-2'>
                <h1>Our After-Sales Support</h1>
                <hr />
            </div>
            <div className='row  mt-2 mt-md-3 mt-lg-4 d-flex justify-content-center '>

                <div className='col-lg-3 col-md-3 col-sm-3 col-12 m-1 m-md-2 m-lg-3  d-flex flex-column align-items-center  flex-fill support-container'>
                    <img src={activites} alt='' className='img-fluid p-1' />
                    <h5 className='m-2'>Sales Activities (Parts & Labour)</h5>
                    <p className='mx-lg-2 mx-md-1 mx-1'>This is a one dedicated division, headed by a Country Sales Manager – After Market.</p>
                </div>

                <div className='col-lg-3 col-md-3 col-sm-3 col-12 m-1 m-md-2 m-lg-3  d-flex flex-column align-items-center  flex-fill support-container'>
                    <img src={Aperture} alt='' className='img-fluid p-1' />
                    <h5 className='m-2'>Workshop</h5>
                    <p className='mx-lg-2 mx-md-1 mx-1'>Focuses on repairs, technician’s recruitment & training, warranty administration, time management, and more.</p>
                </div>

                <div className='col-lg-3 col-md-3 col-sm-3  col-12 m-1  m-md-2 m-lg-3  d-flex flex-column align-items-center  flex-fill support-container'>
                    <img src={Codepen} alt='' className='img-fluid p-1'/>
                    <h5 className='m-2'>Inventory & Warehousing</h5>
                    <p className='mx-lg-2 mx-md-1 mx-1'>Managed through Empower PK Group’s support function Supply Chain & Logistics through a dedicated team. Their KPIs include Spare Parts Availability, inventory turn days, etc.</p>
                </div>
                
            </div>
        </div>
    )
}

export default AfterSalesHeader;
