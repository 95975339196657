import React from 'react'
import AboutUs from '../components/AboutUs'
import Background from '../components/Background'
import nasa from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg'
import LogoComp from '../components/LogoComp'
import Cummins from '../images/1200px-Cummins_logo.svg.png';
import Perkins from '../images/2560px-Perkins-Logo.svg.png';
import Yangdong from '../images/logo-yangdong-engines-metro-manila-philippines.jpg';
import CanadianSolar from '../images/canadian-solar-logo-400x200.png'

function Aboutus() {
  const logos = [
    { src: Cummins, alt: 'Logo 1' },
    { src: Perkins, alt: 'Logo 2' },
    { src: Yangdong, alt: 'Logo 3' },
    { src: CanadianSolar, alt: 'Logo 4' },
    
  ];
  return (
    <div>
    <Background title="About Us" backgroundImage={nasa} />
    
    <AboutUs />

    <LogoComp mainHeading="Our Partners" logos={logos} />
    </div>
  )
}

export default Aboutus