import React from 'react';
import './others.css';
import inmesol from '../images/30773-14530165.jpg';
import visa from '../images/visa-hero.png'
function InternationalCollaboration() {
  return (
    <div className='container-fluid mb-5'>
      <div className='row p-lg-4 p-md-2 m-sm-1 p-1 my-lg-3 my-md-3 my-sm-1 my-1 mx-lg-5 mx-md-2 mx-sm-2 mx-1 text-box-international'>
        <p>Empower PK offers a wide range of world-leading diesel generators, from 9 kVA to 30,000 kVA, to meet your power needs. We provide 24/7 after-sales support, ensuring expert assistance is always available. With genuine spare parts on hand, we guarantee the reliability and longevity of your equipment.</p>
      </div>

      <div className='row  mx-lg-5 mx-md-5 mx-sm-1 my-1  p-2   justify-content-center '>

        <div className='col-12 col-lg-6 col-md-7 col-sm-12   generator-image p-0 mr-md-0 mr-lg-5'>
          <img src={inmesol} alt='' />
        </div>
        <div className='col-12 col-lg-5 col-md-5 col-sm-12  mr-0 p-2'>
          <div className='txt-international p-2'>
            <p>
              We are the authorized distributors of iNMESOL, a leader in the power generation industry. iNMESOL is renowned for its commitment to high-quality and reliable generator solutions. Their products are trusted worldwide for their durability and performance.

            </p>

          </div>

        </div>
      </div>


      <div className='row  mx-lg-5 mx-md-5 mx-sm-1 my-1  p-2   justify-content-center mt-4 '>

        <div className='col-12 order-lg-2 order-md-2 order-1 col-lg-6 col-md-7 col-sm-12  generator-image p-0 mr-md-0 ml-lg-5 '>
          <img src={visa} alt='' />
        </div>
        <div className='col-12 col-lg-5 order-md-1  order-lg-1 order-2 col-md-5 col-sm-12  mr-0 p-2'>
          <div className='txt-international p-2'>
            <p>
              VISA S.p.A. provides reliable solutions for any power needs. A leading international player in manufacturing of Generating Sets and Power Stations, one of the finest expressions of Italian technology and innovation in the energy sector.
            </p>

          </div>

        </div>
      </div>



    </div>
  );
}

export default InternationalCollaboration;
