import React from 'react';
import Empower from '../images/Empower Logo footer.png';
import './footer.css';

function Footer() {
    return (
        <div className='container-fluid main mt-2'>
            <div className='row d-flex justify-content-center py-3'>
                <div className='col-lg-4 col-md-6 col-12 p-0  d-flex  flex-column align-items-center'>
                    <div className='Empower-Logo p-0  '>
                        <img src={Empower} alt='' className='img-fluid' />
                    </div>
                    <p className="para p-2">
                        "We've specialized in delivering reliable power generation solutions and personalized services to our valued customers"
                    </p>
                </div>
                <div className='col-lg-3 py-3 col-md-4 col-11  d-flex flex-column box'>
                    <h4>Sitemap</h4>
                    <hr />
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/aboutus">About Us</a></li>
                        <li> <a href="/internationalcollaboration">International Collaborations</a></li>
                        <li><a href="/aftersale">After Sales Support</a></li>
                        <li><a href="/contact">Contact Us</a></li>


                    </ul>
                </div>
                <div className='col-lg-2 py-3 col-md-6 col-11  d-flex flex-column box'>
                    <h4>Products</h4>
                    <hr />
                    <ul>
                        <li> <a  href="/product/PerkinsGenerator">Perkins Generator</ a></li>
                         
                        <li> <a  href="/product/CumminsGenerators">Cummins Generator</ a></li>
                         
                        <li> <a  href="/product/IndustrialGradeGenerator">Industrial Grade Generator</ a></li>
                         
                        <li> <a  href="/product/ProtableGenerator">Portable Generator</ a></li>
                         
                        <li> <a  href="/product/GeneratorsAndAlternators">CKD Generator and Alternators</ a></li>
                         
                        <li> <a  href="/product/Solarsolutions">Solar Solutions</ a></li>
                    </ul>
                </div>
                <div className='col-lg-3 py-3 col-md-4 col-11  d-flex flex-column box'>
                    <h4>Contact Us</h4>
                    <hr />
                    <p>0336-1110077<br />
                        info@empower.pk <br />
                        Lahore-Pakistan</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
