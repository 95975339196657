import React from 'react'
import './others.css'
function ProductDetails({title, Description, image1, image2 }) {
  return (
    <div className='container-fluid mt-lg-4  '>
      <div className='row '>
        <div className='col-12 mb-sm-3 order-1 col-lg-6 col-md-12  product-text-box  px-lg-4 px-3 '>
         
          <div className='product-text '>
          <h4>{title}</h4>
          <h2 className='mr-lg-5'>{Description.heading}</h2>
          <p className='mr-lg-5 mr-md-0 mr-0 mt-1'>{Description.text}</p>

          </div>

        </div>
        <div className='col-12 order-2 col-lg-6 col-md-12 fff '>
                    <div className='image-container-product'>
                        <img src={image2} alt='' className="image-p image1-p  image-fluid" />
                        <img src={image1} alt='' className="image-p image2-p  image-fluid" />
                    </div>

                </div>

      </div>
    

    </div>
  )
}

export default ProductDetails