import React from 'react'
import './others.css'
import EventImage from '../images/eevent.png'
function Eventsdetail() {
    return (
        <div className='container-fluid mt-lg-4  '>
            <div className='row '>
                <div className='col-12 mb-sm-3 order-1 col-lg-6 col-md-12   product-text-box  px-lg-5 px-3 '>

                    <div className='product-text '>
                        <h4>Events</h4>
                        <h2 className='mr-lg-5'>EmpowerPK has been actively engaging with the community through a series of impactful events.</h2>
                        <p className='mr-lg-5 mr-md-0 mr-0 mt-1'>We are thrilled to announce our participation in upcoming industry trade shows, where we’ll showcase innovative developments and insights on efficient power generation. We invite everyone to join us as we continue to empower communities with reliable energy solutions!</p>

                    </div>

                </div>
                <div className='d-none d-lg-block order-2 col-lg-6 fff '>
                    <div className='image-container-events'>
                    <img src={EventImage} alt='' className="image-e event-i image-fluid" />

                    </div>

                </div>

            </div>


        </div>
    )
}

export default Eventsdetail