import React from 'react'
import Imageslider from '../components/Imageslider'
import Internationalauthcomp from '../components/Internationalauthcomp'
import AboutUs from '../components/AboutusComp'
import Scroller from '../components/Scroller'
import LogoComp from '../components/LogoComp'
import Experience from '../components/Experience'
import Cummins from '../images/1200px-Cummins_logo.svg.png';
import Perkins from '../images/2560px-Perkins-Logo.svg.png';
import Yangdong from '../images/logo-yangdong-engines-metro-manila-philippines.jpg';
import CanadianSolar from '../images/canadian-solar-logo-400x200.png'
import ProductRangeComp from '../components/ProductRangeComp'
import inmesol from '../images/inmesol.jpg'
import multiline from '../images/multiline.png'
function Home() {
  const logos = [
    { src: Cummins, alt: 'Logo 1' },
    { src: Perkins, alt: 'Logo 2' },
    { src: Yangdong, alt: 'Logo 3' },
    { src: CanadianSolar, alt: 'Logo 4' },
    { src: inmesol, alt: 'Logo 5' },
    { src: multiline, alt: 'Logo 6' },
    
  ];

  return (
    <div>
      <Imageslider />
      <Scroller />
      <AboutUs />
      <ProductRangeComp />
      
      <Experience />
      <Internationalauthcomp />
      <LogoComp mainHeading="Our valued Partners" subHeading="Organizations that trust us" logos={logos} />
      

    </div>
  )
}

export default Home