import React from 'react'
import InternationalAuth from '../images/iqram-o-dowla-shawon-Wk2to7RYKHo-unsplash.jpg'
import aftersales from '../images/emmanuel-ikwuegbu-_2AlIm-F6pw-unsplash.jpg'
import './homepg.css'
import { Link } from 'react-router-dom'
function Internationalauthcomp() {
  return (
<div className="container-fluid mt-1 p-1 px-0 auth">
      <div className="row m-0 px-0   ">
        <div className="col-lg-6 col-12 m-0 mt-0 mt-md-2 mt-lg-4  auth-img   ">
          <img src={InternationalAuth} alt='authorization' className='img-fluid   img-css  ' />
        </div>
        <div className="col-lg-6 col-12 m-0 mt-0 mt-md-2 mt-lg-4   p-lg-4 p-2  text-auth-box">
            <div className='mr-0 ts tb mt-lg-4  '>
            <h2 >INTERNATIONAL AUTHORIZATION</h2>
          <p className='mb-lg-5 mb-md-3 mb-3'>We are authorized distributors of leading international brands like Inmesol, Grupel, Perkins, and more. Trusted for their quality and reliability, these brands ensure top-notch solutions for your power generation needs.</p>
         
            <Link to="/internationalcollaboration">
              <button className='button'>
                Learn More
              </button>
            </Link>
                
            </div>
          
        </div>
      </div>

      <div className="row m-0 px-0 mt-1">
        
        <div className="col-lg-6 col-12 m-0 order-2 order-lg-1  py-lg-4   p-2 text-auth-box">
            <div className='ml-0 tw tb mt-lg-4'>
            <h2 >AFTER SALES SUPPORT</h2>
          <p className='mb-lg-5 mb-md-3 mb-1'>Our experienced team is committed to promptly addressing any queries or concerns, offering technical assistance, maintenance services, and spare parts availability.</p>
          <Link to="/aftersales">
              <button className='button'>
                Learn More
              </button>
            </Link>
                
            </div>
          
        </div>
        <div className="col-lg-6 order-1 order-lg-2 col-12 m-0 mt-0 mt-md-2 mt-lg-4   aft-img   ">
          <img src={aftersales} alt='authorization' className='img-fluid   img-css  ' />
        </div>
      </div>
    </div>

  )
}

export default Internationalauthcomp