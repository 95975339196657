import React from 'react'
import Background from '../components/Background'
import BackgroundImage from '../images/top-view-mechanical-supplies-composition.jpg'
import AfterSalesHeader from '../components/AfterSalesHeader'
import ASCommitment from '../components/ASCommitment'
import ActionService from '../components/ActionService'
function AfterSales() {
    return (
        <div><Background backgroundImage={BackgroundImage} />
        <AfterSalesHeader />
        <ASCommitment />
        <ActionService/>
        </div>
    )
}

export default AfterSales