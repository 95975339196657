import nasa from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg'

//Companies
import Cummins from '../images/1200px-Cummins_logo.svg.png';
import Perkins from '../images/2560px-Perkins-Logo.svg.png';
import Yangdong from '../images/logo-yangdong-engines-metro-manila-philippines.jpg';
import CanadianSolar from '../images/canadian-solar-logo-400x200.png'
import Stanford from '../images/stamford.png'
import LeroySomer from '../images/leroySomer.png'
import Meccalte from '../images/Meccalte.png'
import Energizer from '../images/Energizer.png'
import Longi from '../images/Longi.png'
import Huawei from '../images/Huawei.png'
import Jinko from '../images/Jinko.png'
import Knox from '../images/Knox_logo.png'


//Images

import Perkins1 from '../images/PerkinsImg1.png'
import Perkins2 from '../images/PerkinsImg2.png'
import Cummins1 from '../images/CumminsImg1.png'
import Cummins2 from '../images/CumminsImg2.png'
import CKD1 from '../images/CKDImg1.png'
import CKD2 from '../images/CKDImg2.png'
import PG1 from '../images/PGImg1.png'
import PG2 from '../images/PGImg2.png'
import SS1 from '../images/SSImg1.png'
import SS2 from '../images/SSImg2.png'
// Product data
// Product data

<>Routine <br /> Quotations</>
const productData = {
  'PerkinsGenerator': {
    title: 'Perkins Generator',
    description: {
      heading: 'Fuel efficiency, modern build quality, a wide range of options, and easy availability of services are the benchmarks of Perkins generators in Pakistan. ',
      text: 'EmpowerPK is recognized as the leading power generator company in the country, offering a comprehensive range of Perkins generators sourced directly from the Peterborough UK factory. We guarantee the UK origin of both engines and alternators in our Perkins-powered generators, ensuring unmatched reliability and performance.',
    },
    images: [
      { src: Perkins1 , alt: 'Img 1' },
      { src: Perkins2, alt: 'Img 2' },
    ],
    backgroundImage: nasa,
    logos: [
      { src: Cummins, alt: 'Logo 1' },
      { src: Perkins, alt: 'Logo 2' },
      { src: Yangdong, alt: 'Logo 3' },
    ],
  },
  'CumminsGenerators': {
    title: 'Cummins Generators',
    description: {
      heading: 'Cummins Inc., headquartered in Columbus, Indiana, is a leading manufacturer of engines and power generation products. ',
      text: 'Known for reliability, Cummins generators, including the CT, NT, and KT series, are highly regarded in Pakistan for their performance and value retention. Despite the challenges of lower diesel fuel quality in Pakistan, these generators maintain high efficiency, making them ideal for industrial use across the country.',
    },
    images: [
      { src: Cummins1, alt: 'Img 1' },
      { src: Cummins2, alt: 'Img 2' },
    ],
    backgroundImage: nasa,
    logos: [
      { src: Cummins, alt: 'Logo 1' },
      { src: Perkins, alt: 'Logo 2' },
      { src: Yangdong, alt: 'Logo 3' },
      { src: CanadianSolar, alt: 'Logo 4' },
    ],
  },

  'IndustrialGradeGenerator': {
    title: 'Industrial Grade Generators',
    description: {
      heading: 'These Cummins power generators are perfect for both domestic and commercial use, featuring a reliable engine and alternator combination.',
      text: 'EmpowerPK has installed thousands of these diesel generators across Pakistan. They are quiet, dependable, and easy to maintain, with spare parts and services widely available. Equipped with a Deep Sea controller from the UK, they offer long-term reliability.',
    },
    images: [
      { src: Cummins1, alt: 'Img 1' },
      { src: Cummins2, alt: 'Img 2' },
    ],
    backgroundImage: nasa,
    logos: [
      { src: Cummins, alt: 'Logo 1' },
      { src: Stanford, alt: 'Logo 2' },
      { src: LeroySomer, alt: 'Logo 3' },
      { src: Meccalte, alt: 'Logo 4' },
    ],
  },

  'ProtableGenerator': {
    title: 'Protable Generators',
    description: {
      heading: 'The Energizer® brand stands at the forefront of portable power innovation, offering cutting-edge products and world-first technologies.',
      text: 'With a focus on consumer needs, Energizer® leads the market with groundbreaking generators and power equipment. All products are designed and supported in North America, ensuring exceptional customer service and support.',
    },
    images: [
      { src: PG1, alt: 'Img 1' },
      { src: PG2, alt: 'Img 2' },
    ],
    backgroundImage: nasa,
    logos: [
      { src: Cummins, alt: 'Logo 1' },
      { src: Stanford, alt: 'Logo 2' },
      { src: Energizer, alt: 'Logo 3' },
    ],
  },

  'GeneratorsAndAlternators': {
    title: 'CKD Generators and Alternators',
    description: {
      heading: 'EmpowerPK also provides CKD (Complete Knock Down) generators from a variety of brands.',
      text: 'These CKD generators are assembled locally, offering flexibility and cost efficiency while maintaining high-quality standards. With options from multiple brands, EmpowerPK ensures that customers can find the right generator to meet their specific needs and applications.',
    },
    images: [
      { src: CKD1, alt: 'Img 1' },
      { src: CKD2, alt: 'Img 2' },
    ],
    backgroundImage: nasa,
    logos: [
      { src: Cummins, alt: 'Logo 1' },
      { src: Perkins, alt: 'Logo 2' },
      { src: Yangdong, alt: 'Logo 3' },
    ],
  },

  'Solarsolutions': {
    title: 'Solar Solutions',
    description: {
      heading: 'For 14 years, EmpowerPK has installed power systems for over 650 clients and 1,180 installations. ',
      text: 'We are committed to delivering exactly what we promise, with no compromises or misrepresentations, and back it up with exceptional after-sales support. Over 90% of our business comes from repeat clients and referrals, highlighting our strong customer relationships. Unlike newer companies, EmpowerPK has proven its commitment through a decade of reliable service and support.',
    },
    images: [
      { src: SS1, alt: 'Img 1' },
      { src: SS2, alt: 'Img 2' },
    ],
    backgroundImage: nasa,
    logos: [
      { src: Longi, alt: 'Logo 1' },
      { src: CanadianSolar, alt: 'Logo 2' },
      { src: Jinko, alt: 'Logo 3' },
      { src: Knox, alt: 'Logo 4' },
      { src: Huawei, alt: 'Logo 5' },
    ],
  },



  // Add more products as needed
};



export default productData;
