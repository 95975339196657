import React from 'react'
import Check from '../images/Check square.png'
import './others.css'
function ASCommitmentComp({ title, points }) {

    return (
        <div className='card-comp mt-2 p-3 '>
            <h4>{title}</h4>
            <ul>
                {points.map(point => (
                    <li key={point.id} className="d-flex my-4 mx-2">
                        <div className=' align-items-start '>
                        <img src={Check} alt="Checkmark" className="mt-0 image-fluid" />
                        </div>
                        <div className=' pr-2'>
                        {point.text}
                            
                        </div>
                       
                        
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ASCommitmentComp;
