import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Email from '../images/Email.png'
import Phone from '../images/Phone.png'

function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_crexh0d', 'template_9vy5gdo', form.current, {
        publicKey: '4PAalacbKgco2-rZ3',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };










  return (
    <div className='container-fluid mt-5 mb-5 '>

      <div className='row  d-flex justify-content-center'>
        <div className='Contact-Info col-lg-5 col-md-5 col-11'>
          <div className=' p-lg-5 p-md-4 p-3 '>
            <h2>Contact Us</h2>
            <hr />
            <p className='my-4'>For any inquiries or further details, please don't hesitate to contact us at the following:
            </p>
            <div className=' contact-container d-flex my-3'>
              <img src={Email} alt=' ' className='img-fluid icon-container' />
              <h5 className='jjj ml-3'> info@empower.pk</h5>

            </div>
            <div className=' contact-container d-flex my-3 '>
              <img src={Phone} alt=' ' className='img-fluid icon-container' />
              <h5 className='jjj ml-3' > 0336-1110077</h5>

            </div>
            <p className='mt-4'>Our dedicated team is ready to assist you with all your needs.</p>
          </div>



        </div>

        <div className='col-lg-4 col-md-5 col-11  px-2 mt-sm-2 mt-2 mt-lg-0 mt-md-0'>
          <div className="Form-Container px-3 py-2">
          <form ref={form} onSubmit={sendEmail}>
            <div >
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="user_name" 
                placeholder="Enter your name here"
                required
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="user_email"
                placeholder="Enter your email here"
                required
              />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                placeholder="Type your message here..."
                required
              />
            </div>
            <button type="submit" value="Send">Submit</button>
          </form>

          </div>






          






        </div>

      </div>

    </div>
  )
}

export default ContactForm
