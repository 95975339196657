import React from 'react'
import { useParams } from 'react-router-dom';
import ProductDetail from '../components/ProductDetails';
import productData from '../Data/ProductData';
import LogoComp from '../components/LogoComp'
import Background from '../components/Background'
function Product() {
    const { productName } = useParams();
    const product = productData[productName]; // Get the product data based on route parameter
    
    if (!product) {
        return <div>Product not found</div>;
    }


    return (
        <div>


            <Background title={product.title} backgroundImage={product.backgroundImage} />
            <ProductDetail
                title={product.title}
                Description={product.description}
                image1={product.images[0].src}
                image2={product.images[1].src}
            />
            <LogoComp mainHeading="Our Partners" logos={product.logos} />

        </div>
    )
}

export default Product