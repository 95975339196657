import React from 'react';
import evnt1vid from '../images/event1.mp4';
import './others.css';
import evnt2vid from '../images/vvid2.mp4'
function EventVideos() {
  return (
    <div>
      <div className="container-fluid mt-5">
        <div className='row event-vid-text'>
            <h1>OUR EVENTS</h1>
        </div>
        <div className="row"> 
          <div className="video-container  col-lg-6 col-md-6 col-12">
            <video className="video-player" controls controlsList="nodownload" preload="metadata" width="100%">
              <source src={evnt1vid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-container col-lg-6 col-md-6  col-12">
            <video className="video-player" controls controlsList="nodownload" preload="metadata" width="100%">
              <source src={evnt2vid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventVideos;
