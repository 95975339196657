import React from 'react';
import ASCommitmentComp from './ASCommitmentComp';
import './others.css'; // Import the CSS file for styling

function ASCommitment() {
    const commitments = [
        {
            id: 1,
            title: "Spare Parts Availability",
            points: [
                { id: 1, text: "75% Availability over the counter" },
                { id: 2, text: "99% within 8 Days" },
                { id: 3, text: "Free Delivery Of Spare Parts At Customer's Site" }
            ]
        },
        {
            id: 2,
            title: "Repair & Maintainance",
            points: [
                { id: 1, text: "90% to be completed by the originally promised delivery date and time" },
                { id: 2, text: "95% to be right first time" }
            ]
        },
        {
            id: 3,
            title: "Emergency breakdown calls",
            points: [
                { id: 1, text: "To be responded upon first call - 24/7" },
                { id: 2, text: "Breakdown recovery within reasonable time depending upon travel time & repair volume" }
            ]
        },
        {
            id: 4,
            title: <>Routine <br /> Quotations</>,
            points: [
                { id: 1, text: "To be provided within 24 hours" },
                { id: 2, text: "Rate running contracts (annual) for KA’s" }
            ]
        }
    ];

    return (
        <div className='main-container '>
            <div className='background-layer'></div>
            <div className='content'>
            <div className='row'>
                    <div className='col-12 text-center'>
                        <h1 className='p-3'>Our Commitments</h1>
                    </div>
                </div>
                <div className='row card-container'>
                    {commitments.map(commitment => (
                        <div key={commitment.id} className="col-lg-3 col-md-6 col-sm-6 col-12 mb-2 card-item ">
                            <ASCommitmentComp title={commitment.title} points={commitment.points} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ASCommitment;
