import React from "react";
import Cummins from '../images/1200px-Cummins_logo.svg.png';
import Perkins from '../images/2560px-Perkins-Logo.svg.png';
import Inmesol from '../images/inmesol.jpg';
import Yangdong from '../images/logo-yangdong-engines-metro-manila-philippines.jpg';
import CanadianSolar from '../images/canadian-solar-logo-400x200.png'
import Stamford from '../images/stamford.png'
import LeroySomer from '../images/leroySomer.png'
import Energizer from '../images/Energizer.png'
import Meccalte from '../images/Meccalte.png'
import multiline from '../images/multiline.png'
import './homepg.css'



function Scroller() {
  const images = [Cummins, Perkins, Inmesol, Yangdong, CanadianSolar,Energizer,Stamford,LeroySomer,Meccalte,multiline];


  return (
    <div className="AppContainer">
      <div className="Wrapper">
        <div className="Marquee">
          <div className="MarqueeGroup">
          {images.map((el, index) => (
              <div className="ImageGroup" key={index}>
                <img className="Image" src={el} alt={`Logo ${index + 1}`} />
              </div>
            ))}
          </div>

          <div className="MarqueeGroup">
          {images.map((el, index) => (
              <div className= "ImageGroup" key={index}>
                <img className="Image" src={el} alt={`Logo ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>


    </div>

  );
}

export default Scroller;



