import React from 'react'
import Background from '../components/Background'
import InternationalCollaboration from '../components/InternationalCollaboration'
import nasa from '../images/nasa-Q1p7bh3SHj8-unsplash.jpg'
function InternationalCollabration() {
  return (
    <div>
        <Background title="International Collaborations" backgroundImage={nasa}/>
        <InternationalCollaboration />
    </div>
  )
}

export default InternationalCollabration