import React from 'react';
import './homepg.css';

function Card({ title, image, text }) {
  return (
    <div className="card h-100 p-2 "> {/* Added h-100 to ensure the card takes full height of the column */}
      <img src={image} className="card-img-top" alt="Card " />
      <div className="card-body custom-card">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{text}</p>
      </div>
    </div>
  );
}

export default Card;
